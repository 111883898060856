import React from "react";
import { ButtonMore } from "../../components/elements";
import HeaderWithImage from "../../components/composites/HeaderWithImage";
import { Link } from 'gatsby';
import ArrowImage from "../../assets/svgviewer-output.svg";
import Logo from "../../components/elements/Logo";

const Banner = ({ bannerData }: any) => {
  return (
    <>
      <HeaderWithImage minHeight="100vh"
        bgImage={bannerData?.bgImage}
        bgThumbnail={bannerData?.thumbnail}
      >
        <Logo imageName={"web_logo.svg"} logoWidth={"360px"} />
        <Link to="/about-us">
          <ButtonMore round type="trans" marginTop={"0px"}>
            <span>Learn More</span>
            <img src={ArrowImage} alt="arrow" />
          </ButtonMore>
        </Link>
      </HeaderWithImage>
    </>
  );
};

export default Banner;
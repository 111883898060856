
import React, { FC } from 'react';
import styled from "styled-components";
import Image from "../../components/elements/Image";
import { Link } from "gatsby";
import { down } from 'styled-breakpoints';
interface LogoProps {
  imageName?: string;
  logoWidth?: string;
}

const LogoWrap = styled.div`
  margin: 20px 0;
  flex: 0 1 100px;
  img{
    position: relative;
    width: ${(props: any) => props.width ? props.width : '100px'};
  }

  ${down('md')}{
    flex: 0 1 40px;
    text-align: center;
    img{
      width: ${(props: any) => props.width ? props.width : '40px'};
      max-width:80%;
    }
  }
  ${down('md')} and (orientation: landscape) {
    flex: 0 1 25px;
  }
`;
const Logo: FC<LogoProps> = ({ imageName, logoWidth }) => {
  return (
    <LogoWrap as={Link} to="/" width={logoWidth}>
      <Image
        src={imageName ? imageName : "web_logo.svg"}
        alt={"imagine pool logo"}
      />
    </LogoWrap>
  );
};

export default Logo;

